@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import '../node_modules/ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');


:root {
  --normal-font-size: 0.9rem !important;
  --small-font-size: 0.83rem !important;
  --base-app-color:#446b90;
  --base-button-primary-bg: #3a99d2;
  --base-button-primary-bg-hover: #59b2e8;
  --input-bg-gray: #ececec;
  --base-primary-dark-text-color: #09364D;
  --base-button-sucsses: #009005;
  --base-dark-primary: #0b3045;
}

.material-icons {
  font-family: 'Material Icons' !important; /* Force Material Icons font */
}

*:not(.material-icons) {
  // font-family: "Montserrat",'primeicons', sans-serif !important;
  font-family: LabAdminFont ,sans-serif !important;
  font-size: 12px//var(--normal-font-size);
}

* {
   //font-family: "Montserrat",'primeicons', sans-serif !important;
   font-family: LabAdminFont ,sans-serif !important;
  font-size: 12px //var(--normal-font-size)
}

.font-normal{
  font-size: var(--normal-font-size)
}

.font-small{
  font-size: var(--small-font-size);
}

.main-nav-bg {
  background-color: var(--base-app-color);
}

.bg-primary-dark{
  background-color: var(--base-dark-primary) !important;
}

body {
  background-color: white;
  overflow-y: hidden !important;
}
.border-radius-for-header{
  border-radius: 2px !important;
}
.main-secondary-nav-bg {
  background-color: var(--base-button-primary-bg-hover);
}

.scrollable-parent {
  display: flex;
  /* relevant part */
  flex-direction: column;
  /* relevant part */
  overflow: hidden;
  /* relevant part */
  height: 100vh;
}

.scrollable-child {
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  color: black;
}

.p-highlight {
  background-color: #1f79ce;//var(--base-app-color) !important;
  color: white !important;
}

.fs-underline {
  text-decoration: underline !important;
}

.lh-zero {
  line-height: 0 !important;
}

.bg-dim-gray{
  background-color: var(--input-bg-gray);
}

// .input-bg{
//   background-color: var(--input-bg-gray) !important;
// }

.bg-error {
  background-color: rgb(255, 204, 204);
  border: 2px solid red !important;
}

.app-bg-success {
  background-color:  var(--base-button-sucsses) !important;
}

.width-100 {
  max-width: 100px !important;
  width: 100px !important;
}

.text-unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  user-select: none;
  /* Standard */
}

.flat-btn {
  border-width: 0 !important;
  border-style: none !important;
  background: transparent !important;
  padding: 0;
  margin: 0;
  text-align: left !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border-dim-gray {
  border-color: #6d757cd1;
}

.border-dark-primary {
  border-color: var(--base-dark-primary);
}

.va-label{
  width: 100%;
  line-height: 0;
  color: rgb(89, 89, 89);
}

.va-control{
  width: 100%;
}

.wiz-width{
  width: 87%;
  max-width: 1200px;
}

.bg-flat-tab-select{
  background-color: #f5f7fb;
}

.select-light-blue{
  background-color: rgb(220, 232, 255);
  border: 1px solid cornflowerblue;
  border-radius: 5px;
}


.config-height{
  height: 70vh;
}

.bg-blue {
  background-color: #1f79ce;
}

.text-blue {
  color: #1f79ce;
}

.text-dark-primary {
  color: var(--base-primary-dark-text-color) !important;
}

.border-blue{
  border: #1f79ce;
}

.chk-blue{
  background-color: white;
}
.chk-blue:checked{
  background-color: var(--base-dark-primary);
  border: var(--base-dark-primary);
}

.bg-disabled {
 background-color: #e3e3e3;
}

.text-unselectable {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  user-select: none;
  /* Standard */
}

.fix-overflow {
  overflow: hidden;
}


/** BOOTSTRAP OVERRIDES **/
.table-sm-zero > :not(caption) > * > * {
  padding: 0;
}



/** PRIME NG OVERRIDES **/

.p-dropdown {
  padding-top: 0;
  padding-bottom: 0;
  height: 24px !important;
}

.p-dropdown-label{
  padding: 0;
  //padding-left: 4px;
}

.p-listbox-filter{
  padding-left: 10px;
}

.p-listbox-filter-icon{
  top:57% !important;
}

.p-inputtext{
  padding:0;
  padding-left: 3px;
  padding-right: 3px;
  width: 100%;
  border: 0;
  // background-color: var(--input-bg-gray);
}

.p-inputtextarea{
  padding:0;
  padding-left: 3px;
  padding-right: 3px;
  width: 100%;
}

.p-listbox-item{
  padding: 5px !important;
}

.p-chips {
  width: 100%;
}

.p-chips-multiple-container{
  padding: 1px !important;
}

.p-chips-input-token{
  padding: 0 !important;
}
.label-text-color{
  color: var(--base-primary-dark-text-color) !important;
}


.p-multiselect-token {
  background-color: var(--base-dark-primary) !important;
  color: white !important;
}

.p-button-outlined-dark{
  border-color: var(--base-dark-primary) !important;
  color: var(--base-dark-primary) !important;
  background-color: white !important;
}

.p-button-outlined-dark:hover{
  border-color: var(--base-dark-primary) !important;
  color: white !important;
  background-color: var(--base-dark-primary) !important;
}
html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body
{ margin: 0; font-family:LabAdminFont, sans-serif; font-size: 12px;}
@font-face {
  font-family: LabAdminFont;
  src: url("../src/assets/Nunito/Nunito-Regular.ttf");
}
ol, ul
{
  padding-left: 5px !important;
}
.p-menuitem-text{
  color: black !important;
  font-size: 12px;
  font-weight: 600;
  //padding-left: 1vw;
}
.p-breadcrumb-list
{
  padding-left: 1vw !important;
}
.label-header{
  font-size: 13px !important;
}




/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #9F9F9F !important;
  color: black !important;
}

/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 1.5px !important;
  color: black !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
    border-color: #9F9F9F !important;
    color: black !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: #9F9F9F !important;
  color: black !important;
}


.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above
{
  color: #9F9F9F !important;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {
  color: red!important;
}

